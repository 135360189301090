<template>
  <div>
    <div class="title">
      <div class="name">
        <h3>会员简介</h3>
        <h5>Member Profile</h5>
      </div>
      <div class="search">
        <input @change="getDataList" v-model="title" placeholder="企业查找">
        <img src="../../assets/image/icon/search.png">
      </div>
    </div>
    <div class="content">
      <div class="card" v-for="item in data.records" :key="item.id" @click="toDetails(item.id)">
        <img v-if="item.companiesInformation" :src=url+item.companiesInformation.pic>
        <div class="text">
          <h3 style="cursor: pointer;">{{item.name}}</h3>
          <p>
            企业类型：{{item.companiesInformation.companiesType}} &nbsp;|&nbsp;
            成立时间：{{new Date(item.companiesInformation.establishTime).toLocaleDateString()}}
          </p>
          <p>
            所属行业：{{item.companiesInformation.industry}} &nbsp;|&nbsp;
            经营范围：{{item.companiesInformation.businessScope}}
          </p>
          <p>
            公司地址：{{item.companiesInformation.enrollAddr}}
            </p>
        </div>
      </div>
      <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
    </div>

  </div>
</template>

<script>
import pagination from "@/components/public/pagination";

export default {
  name: "synopsis",
  components: {
    pagination
  },
  data() {
    return {
      title:'',
      url:this.$constContent.imgUrl,
      data:{},
      current:1
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.get("/companies/webSiteGet",{
          params: {
            title:this.title,
            current: this.current,
            size: 5
          }
      }).then((res)=>{
        console.log(res.data)
        this.data = res.data
      })
    },
    toPage(e) {
      this.current = e
      this.getDataList()
    },

    toDetails(id) {
      this.$router.push('/supplyDetails?id='+id)
      this.$emit('getTitle', '详情')
    }
  }
}
</script>

<style scoped>
.title {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.name h3 {
  margin: 0;
}

.name h5 {
  margin-top: 10px;
  margin-bottom: 0;
}

.search {
  width: 30%;
  height: 40px;
  position: relative;
  margin-top: 15px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 0 10px;
}

.search img {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 12px;
  right: 12px;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 90%;
  margin: 10px 3%;
  padding: 2%;
  border-bottom: 1px solid #dfdfdf;
  display: block;

}
.card:hover{
  background-color: #f8f8f8;
}
.card:hover .card>img{
  box-shadow: 5px 5px 5px #eeeeee;
}

.card img {
  width: 20%;
  float: left;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 3px;
}

.card .text {
  width: calc(80% - 10px);
  padding-left: 10px;
  float: left;

}

.card .text p {
  font-size: 14px;
  color: #999999;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  cursor: pointer;

}
</style>
<template>
  <div class="pagination">
    <ul>
      <li v-if="thisPage>1" @click="click(1)">首页</li>
      <li v-if="thisPage-1>0" @click="click(thisPage-1)">上一页</li>
      <li class="thisPage">{{thisPage}}</li>
      <li v-if="thisPage+1<=pages" @click="click(thisPage+1)">{{thisPage+1}}</li>
      <li v-if="thisPage+2<=pages" @click="click(thisPage+2)">{{thisPage+2}}</li>
      <li v-if="thisPage+3<=pages">...</li>
      <li v-if="thisPage+1<=pages" @click="click(thisPage+1)">下一页</li>
      <li>共{{pages}}页</li>
      <li @click="click(pages)">末页</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    thisPage: {
      type: Number,
      default:1
    },
    pages:{
      type:Number
    }
  },
  methods:{
    click(page){
      this.$emit('toPage',page)
    }
  }

}
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 10px 0;
}

ul {
  height: 40px;
  padding: 0;
  margin: 0;
}

ul li {
  float: left;
  width: 50px;
  height: 20px;
  margin: 10px;
  list-style: none;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  cursor: hand;
}
.thisPage{
  background: #ff3300;
  color: #ffffff;
  border-radius: 3px;
}
</style>